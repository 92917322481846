import CampaignList from '../../../components/campaign/list/campaignList';

export default {
  name: 'WebPushCampaignList',
  data() {
    return {
      campaignChannelType: 'webPush',
      campaignChannel: 'Web Push'
    };
  },
  components: {
    CampaignList
  },
  methods: {},
  mounted() {}
};
